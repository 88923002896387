import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TimerpageComponent } from './components/timerpage/timerpage.component';
// import {MainpageComponent} from './components/mainpage/mainpage.component';
import { VideopageComponent } from './components/videopage/videopage.component';
import { EventsComponent } from './components/events/events.component';

const routes: Routes = [
  // { path: 'main', component: MainpageComponent },
  { path: 'timer', component: TimerpageComponent },
  { path: 'video', component: VideopageComponent },
  { path: 'events', component: EventsComponent },
];

@NgModule({
  declarations: [
    VideopageComponent,
    TimerpageComponent,
    EventsComponent
  ],
  imports: [FormsModule,CommonModule, RouterModule.forRoot(routes)],
  exports: [FormsModule, RouterModule, CommonModule]
})
export class AppRoutingModule { }

<footer id="footer">
    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6">
            <div class="footer-info">
              <h3>
                <img src="../../../assets/img/ibmwfoterlogo.png" style="max-height: 6vh;" /></h3>
              <p>
                1105 Cottonwood Ct, North Brunswick Township, <br>
                NJ 08902<br><br>
                <strong>Phone:</strong> +1 888-770-5332<br>
                <strong>Email:</strong> raj@ibuildmywealth.com<br>
              </p>
              <div class="social-links mt-3">
                <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                <a href="#" class="google-plus"><i class="bi bi-skype"></i></a>
                <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li> <a href="#about">About us</a></li>
              <li> <a href="#services">FAQ's</a></li>
              <li> <a href="#contact">Contact Us</a></li>
            </ul>
          </div>

          <div class="col-lg-4 col-md-6 footer-newsletter">
            <h4>Get a Free Quote</h4>
            <p>We Provide Life Insurance For You And Your Loved Ones</p>
            
              <div class="d-grid mx-auto"><a href="/video">
                <button type="button" class="btn btn-primary float-right">
                  Get Started <i class="bi bi-chevron-right"></i>
                </button></a>
              </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>I Build My Wealth</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        Designed by <a href="https://ibuildmywealth.com/">ibuildmywealth dev</a>
      </div>
    </div>
  </footer><!-- End Footer -->
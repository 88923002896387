<div class=" align-items-center pt-4">
  <div class="container pt-4 mt-5" style="padding-bottom: 1.5rem;">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 mb-md-0 mb-4 col-12">
            <div class="p-relative w-100">
              <p class="h2"><strong><span style="color: #AFD136; font-size:1.375rem; font-weight: 700;">I BUILD MY WEALTH</span></strong></p>
            </div>
            <div style="margin-bottom: 20px;">
              <span class="">
                <h3><b>6 Steps To Financial Security</b></h3>
                <p style="font-size: 15px; color: #757575;">Success begins with Financial</p>
              </span>
            </div>
            <div class="row">
              <div class="col-lg-10 col-md-11">
                <ul class="info_list list-unstyled mb-0">
                  <li class="" id="js-list-item-1" style="margin-bottom: -20px;">
                    <div class="js-cloneable" id="js-list-text-1" data-clone="js-list-item-1"
                      data-widget-target="js-list-text-1" data-widget-type="text">
                        <div class="steps">
                          <i><img src="../../../assets/img/register-icons/finance.png" style="height: 45px;" /></i>
                          <h6><b>Increase Cash Flow</b></h6>
                          <p>Earn additional income, Manage expenses</p><br>
                        </div>
                    </div>
                  </li>
                  <li class="" id="js-list-item-2" style="margin-bottom: -15px;">
                    <div class="js-cloneable" id="js-list-text-2" data-clone="js-list-item-2"
                      data-widget-target="js-list-text-2" data-widget-type="text">
                      <div class="steps">
                        <i><img src="../../../assets/img/register-icons/finances.png" style="height: 45px;" /></i>
                        <h6><b>Debit Management</b></h6>
                        <p>Consolidate debt, Eliminate debt</p><br>
                      </div>
                    </div>
                  </li>
                  <li class="" id="js-list-item-3" style="margin-bottom: -15px;">
                    <div class="js-cloneable" id="js-list-text-3" data-clone="js-list-item-3"
                      data-widget-target="js-list-text-3" data-widget-type="text">
                      <div class="steps">
                        <i><img src="../../../assets/img/register-icons/piggy-bank.png" style="height: 45px;" /></i>
                        <h6><b>Emergency Fund</b></h6>
                        <p>Save 3-6 month income, Prepare for unexpected expenses</p><br>
                      </div>
                    </div>
                  </li>
                  <li class="" id="js-list-item-4" style="margin-bottom: -15px;">
                    <div class="js-cloneable" id="js-list-text-4" data-clone="js-list-item-4"
                      data-widget-target="js-list-text-4" data-widget-type="text">
                      <div class="steps">
                        <i><img src="../../../assets/img/register-icons/budget.png" style="height: 45px;" /></i>
                        <h6><b>Proper Protection</b></h6>
                        <p>Protect against loss of income, Protect family assets</p><br>
                      </div>
                    </div>
                  </li>
                  <li class="" id="js-list-item-5" style="margin-bottom: -15px;">
                    <div class="js-cloneable" id="js-list-text-5" data-clone="js-list-item-5"
                      data-widget-target="js-list-text-4" data-widget-type="text">
                      <div class="steps">
                        <i><img src="../../../assets/img/register-icons/graph-bar.png" style="height: 45px;" /></i>
                        <h6><b>Build Wealth</b></h6>
                        <p>Outpace inflation, Active money management</p><br>
                      </div>
                    </div>
                  </li>
                  <li class="" id="js-list-item-6" style="margin-bottom: -15px;">
                    <div class="js-cloneable" id="js-list-text-6" data-clone="js-list-item-6"
                      data-widget-target="js-list-text-6" data-widget-type="text">
                      <div class="steps">
                        <i><img src="../../../assets/img/register-icons/safebox.png" style="height: 45px;" /></i>
                        <h6><b>Preserve Wealth</b></h6>
                        <p>Avoid probate, reduce taxation</p><br>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-12" style="margin-top: 8px;">
            <div class="card" style="border-radius: 10px;">
              <video controls style="margin: 15px; border-radius: 5px;">
                <source src="https://assets.codepen.io/6093409/river.mp4" type="video/mp4">
                <source src="https://assets.codepen.io/6093409/river.mp4" type="video/ogg">
                Your browser does not support HTML video.
              </video>
              <div class="card-body">
                <div class="d-flex flex-row">
                  <div class="p-2 profile"><img
                      src="../../../assets/img/profile.jpeg" alt="img"
                      title="Jody Humphrey" data-original-title="Jody Humphrey" class="img-circle img-cover"></div>
                  <div class="p-2">
                    <div style="padding-top: 25px;">
                    <p class="js-presenter-name presenter_name mb-0" style="font-weight: 500;
                                    font-size: 1.125rem;">RAJESH KALASAPATI</p>
                    <p class="js-presenter-bio presenter_bio mb-0" style="color: #757575;
                                    font-weight: 300;">Financial Professional</p>
                                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header" style="text-align: center;">
              <h5 class="modal-title" style="color: #AFD136;" id="exampleModalLabel">The Best Professional Business Opportunity in the World
              </h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form style="margin: 20px;">
                <div class="input-group input-group-lg mb-3">
                  <span class="input-group-text">
                    <i class="bi bi-calendar-event"></i>
                  </span>
                  <select id="selTime" name="selTime" [(ngModel)]="selDate" class="form-control"
                    style="font-size: 1rem;" placeholder="Select date" class="form-control">
                    <!-- <option selected>Choose...</option> -->
                    <option *ngFor="let date of datesArray" value={{date.date}}>{{date.date | date: 'medium'}}</option>
                  </select>
                  
                </div>
                <div class="input-group input-group-lg mb-3">
                  <span class="input-group-text">
                    <i class="bi bi-clock"></i>
                  </span>
                  <select id="selTime" name="selTime" [(ngModel)]="selTime" style="font-size: 1rem;" class="form-control"
                    placeholder="Select date" class="form-control">
                    <option selected>Eastern Times (US and Canada)</option>
                  </select>
                </div>
                <div class="input-group input-group-lg mb-3">
                  <span class="input-group-text"><i class="bi bi-person"></i></span>
                  <input type="text" style="font-size: 1rem;" id="selFName" name="selFName" [(ngModel)]="selFName"
                    class="form-control" placeholder="Enter first name...">
                </div>
                <div class="input-group input-group-lg mb-3">
                  <span class="input-group-text"><i class="bi bi-person"></i></span>
                  <input type="text" style="font-size: 1rem;" id="selLName" name="selLName" [(ngModel)]="selLName"
                    class="form-control" placeholder="Enter last name...">
                </div>
                <div class="input-group input-group-lg mb-3">
                  <span class="input-group-text"><i class="bi bi-envelope"></i></span>
                  <input type="text" style="font-size: 1rem;" id="selMail" name="selMail" [(ngModel)]="selMail"
                    class="form-control" placeholder="Enter email addres...">
                </div>
                <div class="input-group input-group-lg mb-3">
                  <span class="input-group-text"><i class="bi bi-telephone"></i>
                  </span>
                  <input type="text" style="font-size: 1rem;" id="selPhNo" name="selPhNo" [(ngModel)]="selPhNo"
                    class="form-control" placeholder="Enter phone number...">
                </div>
                <div class="d-grid mx-auto">
                  <button class="btn btn-primary" type="button" (click)="submitingData()" style="background-color: #AFD136; border-color: #AFD136;"
                    [disabled]="!validateFields()" data-bs-dismiss="modal">Submit form</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class=" icon-boxes">
          <div class="row">
            <div class="col-md-6 col-lg-4 align-items-stretch mb-2 mb-lg-0">
              <div class="card icon-box">
                <div class="card-body">
                  <h5 class="card-title title">
                    <div class="icon"><i class="bi bi-file-text"></i></div>
                    Register
                  </h5>
                  <p>We Provide Life Insurance For You And Your Loved Ones</p>
                  <div class="d-grid mx-auto">
                  <button type="button" class="btn btn-sm btn-primary float-right reg-button" data-bs-toggle="modal"
                    data-bs-target="#exampleModal">
                    Registration <i class="bi bi-chevron-right"></i>
                  </button></div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-4 align-items-stretch mb-2 mb-lg-0">
              <div class="card icon-box">

                <div class="card-body">
                  <h5 class="card-title title">
                    <div class="icon"><i class="bi bi-calendar-event"></i></div>
                    Demo hours
                  </h5>
                  <div class="" style="text-align: center;">
                    <div *ngFor="let dateNow of datesArray">
                    <span>{{dateNow.date | date: 'medium'}}</span>
                    </div><br>
                    <span style="color: #757575;">Eastern Times (US and Canada)</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-4 align-items-stretch mb-2 mb-lg-0">
              <div class="card icon-box">
                <div class="card-body">
                  <h5 class="card-title title">
                    <div class="icon"><i class="bi bi-stopwatch"></i></div>
                    Timer
                  </h5>
                  <!-- <div class=""> -->
                  <div class="timer d-flex">
                    <figure>
                      <div><span *ngIf="timeDifference && timeDifference.day<=9">0</span>{{timeDifference.day}}</div>
                      <figcaption>DAYS</figcaption>
                    </figure>
                    <figure>
                      <div><span *ngIf="timeDifference && timeDifference.hour<=9">0</span>{{timeDifference.hour}}</div>
                      <figcaption>HOURS</figcaption>
                    </figure>
                    <span>:</span>
                    <figure>
                      <div><span *ngIf="timeDifference && timeDifference.min<=9">0</span>{{timeDifference.min}}</div>
                      <figcaption>MINUTES</figcaption>
                    </figure>
                    <span>:</span>
                    <figure>
                      <div><span *ngIf="timeDifference && timeDifference.sec<=9">0</span>{{timeDifference.sec}}</div>
                      <figcaption>SECONDS</figcaption>
                    </figure>
                  </div>
                  <!-- </div> -->
                </div>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class='fixed-bottom'></div> -->
<!-- <button class="accordion-button"
    style="width: auto; margin-left: 92%; background-color: rgba(37, 55, 126, 0.95); border-radius: 10px 10px 0px 0px;"
    id="headingOne" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"
    aria-controls="collapseOne">
  </button>
  <div class="accordion" id="accordionExample">

    <div class="accordion-item" style="background-color: rgba(65, 89, 185, 0.95); color: white;">
      <div id="collapseOne" class="accordion-collapse collapse show content_area align-items-center"
        aria-labelledby="headingOne" data-bs-parent="#accordionExample">
        <div class="accordion-body container pt-1">
          <div class="row">
            <div class="col-md-5">Wednesday, 14 July 2021, 6:00 PM<br>
              Wednesday, 14 July 2021, 6:00 PM<br>
              Wednesday, 14 July 2021, 6:00 PM
            </div>
            <div class="col-md-7 timerdiv">
              <div class="d-flex">
                <div class="timer d-flex">
                  <figure>
                    <div>00</div>
                    <figcaption>DAYS</figcaption>
                  </figure>
                  <figure>
                    <div>00</div>
                    <figcaption>HOURS</figcaption>
                  </figure>
                  <span>:</span>
                  <figure>
                    <div>00</div>
                    <figcaption>MINUTES</figcaption>
                  </figure>
                  <span>:</span>
                  <figure>
                    <div>00</div>
                    <figcaption>SECONDS</figcaption>
                  </figure>
                </div>
              </div>
              <div class="">
                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  Register
                </button>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {
  
  selDate: any = new Date();
  selTime: any = "some time";
  selFName: any;
  selLName: any;
  selMail: any;
  selPhNo: any;
  datesArray = [];

  constructor(public http: HttpClient) { }

  ngOnInit(): void {
    var curData = new Date();
    for (let i = 0; i < 3; i++) {
      this.datesArray.push({ date: curData.setHours(curData.getHours() + 2) });
    }
  }

  submitingData() {
    // var crrrDate = this.selDate - new Date()
    
    var body = {
      firstName: this.selFName,
      lastName: this.selLName,
      email: this.selMail,
      phone: this.selPhNo,
      subject: "Event registration",
      // selectedDate: new Date(parseInt(this.selDate)),
      // timezone: this.selTime,
      registerType: "events"
    }
    console.log(body);
    this.http.post('https://ibuildmywealth.herokuapp.com/mail', body, {headers: {'Content-Type': 'application/json'}}).subscribe(result => {
      console.log(result);
    }, err => {
      console.log(err);
    })
    // console.log('inside submit', this.selDate);
  }

  
  validateFields() {
    // console.log(this.selDate, ' ', this.selFName, ' ', this.selLName, ' ', this.selMail, ' ', this.selPhNo, ' ', this.selTime)
    if(this.selDate && this.selTime && this.selFName
       && this.selLName && this.selMail && this.selPhNo
       && this.selFName && this.selFName) {
      return true;
    } else return false;
  }


}

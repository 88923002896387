<!-- ======= Header ======= -->


<!-- <section id="hero"> -->
  <div class="hero-div" id="toppage">
    <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active"
          aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
          aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
          aria-label="Slide 3"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3"
          aria-label="Slide 4"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../../../assets/img/sliders/S1.jpg" class="d-block w-100" alt="..."
            style="margin-top: 85px; max-height: 78vh;">
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/sliders/S2.jpg" class="d-block w-100" alt="..."
            style="margin-top: 85px; max-height: 78vh;">
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/sliders/s3.jpeg" class="d-block w-100" alt="..."
            style="margin-top: 85px; max-height: 78vh;">
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
        <div class="carousel-item">
          <img src="../../../assets/img/sliders/s4.jpeg" class="d-block w-100" alt="..."
            style="margin-top: 85px; max-height: 78vh;">
          <div class="carousel-caption d-none d-md-block">
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
<!-- </section> -->
<section id="clients" class="clients ">
  <div class="container">
    <div class="">
      <h4 style="text-align: center;
      margin-top: 10px;
      font-weight: 600;
      margin-bottom: 1rem; font-family: Century Gothic;">We shop highly rated Insurance companies<br>
        to save you time & money.</h4>
    </div>
    <!-- <div class="row section-bg" style="min-height: 125px;">

      <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center client-bg">
        <img src="../../../assets/img/clients/aig.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center client-bg">
        <img src="../../../assets/img/clients/allianz.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center client-bg">
        <img src="../../../assets/img/clients/fg.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center client-bg">
        <img src="../../../assets/img/clients/na.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center client-bg">
        <img src="../../../assets/img/clients/nw.png" class="img-fluid" alt="">
      </div>

      <div class="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center client-bg">
        <img src="../../../assets/img/clients/prudential.jpg" class="img-fluid" alt="">
      </div>

    </div> -->


    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" (init)="slickInit($event)"
    (breakpoint)="breakpoint($event)">
    <!-- <div class="" > -->
    <div ngxSlickItem *ngFor="let slide of slides" class="slide  d-flex align-items-center justify-content-center client-bg">
      <img src="../../../assets/img/clients/{{slide}}" alt="" width="100%">
    </div>
  <!-- </div> -->
  </ngx-slick-carousel>

  </div>
</section>
<!-- ======= Hero Section ======= -->
<!-- <section id="hero">

    <div id="heroCarousel" data-bs-interval="5000" class="carousel slide carousel-fade" data-bs-ride="carousel">

      <ol class="carousel-indicators" id="hero-carousel-indicators"></ol>

      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img class="d-block w-100" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3gJ3z0avzNGkcjGitKnu-07YhUh1Hcd29OQ&usqp=CAU" alt="First slide">
          </div>
          <div class="carousel-item">
            <img class="d-block w-100" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR3gJ3z0avzNGkcjGitKnu-07YhUh1Hcd29OQ&usqp=CAU" alt="Third slide">
          </div>
        </div>
        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>

    </div>
  </section> -->
<!-- End Hero -->

<main id="main">

  <!-- ======= About Section ======= -->
  <section id="about" class="services">
    <div class="container">

      <div class="section-title">
        <h2>About Us</h2>
      </div>
      <div style="text-align: center; font-weight: 700;">
        <p>
          We Can Help With Your All Your Insurance Needs</p>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="icon-box" style="min-height: 206px;">
            <i>
              <div class="about-icon">
                <img src="../../../assets/img/icons/save-money.svg" style="width: 50px;" />
              </div>
            </i>
            <h4><a href="#">Life Insurance</a></h4>
            <p>The protection you & your family need at the price you can afford. How about life time guaranteed with
              medical benefits.</p>
          </div>
        </div>
        <div class="col-md-4 mt-4 mt-md-0">
          <div class="icon-box" style="min-height: 206px;">
            <i>
              <div class="about-icon">
                <img src="../../../assets/img/icons/familiar-group.svg" style="width: 50px;" />
              </div>
            </i>
            <h4><a href="#">Retirement Plans</a></h4>
            <p>Start saving for your retirement today. Tax savings, health benefits, life time, access to your savings
              at any time for any purpose.</p>
          </div>
        </div>
        <div class="col-md-4 mt-4 mt-md-0">
          <div class="icon-box" style="min-height: 206px;">
            <i>
              <div class="about-icon">
                <img src="../../../assets/img/icons/basketball.svg" style="width: 50px;" />
              </div>
            </i>
            <h4><a href="#">Education Plans</a></h4>
            <p>Kids education costs are increasing start when they are young. Use the savings without an impact on
              Financial Aid or Tax implications.</p>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End About Section -->

  <!-- ======= FAQs Services Section ======= -->
  <section id="services" class="services">
    <div class="container">
      <div class="section-title">
        <h2>FAQ's</h2>
      </div>
      <div style="text-align: center; font-weight: 700;">
        <p>
          Ask anything related to life Insurance, Retirement, Wealth building, Kids Education, etc., <br>we can help you set
          the right path forward.</p>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="icon-box" style="min-height: 206px;">
            <i class="bi bi-briefcase" style="float: left;"></i>
            <h4 class="faq-h4"><a href="#">Why should I choose IBMW for Insurance?</a></h4>
            <p class="faq-para">I Build My Wealth can be your one-stop for life insurance, Retirement Plans, building
              wealth, Will & Trust and many more! Our difference is our passion to protect families—like yours—and match
              you with the best carrier suitable for your needs.</p>
          </div>
        </div>
        <div class="col-md-6 mt-4 mt-md-0">
          <div class="icon-box" style="min-height: 206px;">
            <i class="bi bi-card-checklist" style="float: left;"></i>
            <h4 class="faq-h4"><a href="#">What companies does IBMW represent?</a></h4>
            <p class="faq-para">I Build My Wealth represents more than 10 highly rated life insurance and Annuity
              carriers. See the full lists of carriers in the above banner:</p>
          </div>
        </div>
      </div>

    </div>
  </section><!-- End Services Section -->

  <!-- ======= Contact Section ======= -->
  <section id="contact" class="contact">
    <div class="container">
      <div class="section-title">
        <h2>Contact Us</h2>
      </div>
      <!-- <div>
          <iframe style="border:0; width: 100%; height: 270px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe>
        </div> -->
      <div>
        <div class="row ">
          <div class="col-lg-4">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>1105 Cottonwood Ct, North Brunswick Township, NJ 08902</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>raj@ibuildmywealth.com</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+1 888-770-5332</p>
              </div>

            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">

            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" [(ngModel)]="fulltName" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" [(ngModel)]="email" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" [(ngModel)]="subject" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" [(ngModel)]="message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit" (click)="contactus()">Send Message</button></div>
            </form>

          </div>

        </div>
      </div>
    </div>
  </section><!-- End Contact Section -->

</main><!-- End #main -->

<!-- ======= Footer ======= -->

<!-- <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a> -->

<router-outlet></router-outlet>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timerpage',
  templateUrl: './timerpage.component.html',
  styleUrls: ['./timerpage.component.css'],
})
export class TimerpageComponent implements OnInit {
  selDate: any;
  selTime: any;
  selFName: any;
  selLName: any;
  selMail: any;
  selPhNo: any;
  datesArray = [];
  constructor() {}

  ngOnInit(): void {
    var curData = new Date();
    // curData.setHours( curData.getHours() + 2 );
    // console.log(new Date(), " curData ", curData);
    for (let i = 0; i < 3; i++) {
      this.datesArray.push({ date: curData.setHours(curData.getHours() + 2) });
    }

    console.log(this.datesArray);
  }
  timeDifference = { day: 0, hour: 0, min: 0, sec: 0 };
  submitingData() {
    // var crrrDate = this.selDate - new Date()
    setInterval(() => {
      var currDate = Date.now();
      new Date(parseInt(this.selDate)).getSeconds() - new Date().getSeconds();
      var difference = this.selDate - Date.now();

      var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
      difference -= daysDifference * 1000 * 60 * 60 * 24;

      var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      var minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      var secondsDifference = Math.floor(difference / 1000);
      this.timeDifference = {
        day: daysDifference,
        hour: hoursDifference,
        min: minutesDifference,
        sec: secondsDifference,
      };
      console.log(
        'difference = ' +
          daysDifference +
          ' day/s ' +
          hoursDifference +
          ' hour/s ' +
          minutesDifference +
          ' minute/s ' +
          secondsDifference +
          ' second/s '
      );
      // console.log("currDate ", new Date(parseInt(this.selDate)).getSeconds()-new Date().getSeconds() );
    }, 1000);
    console.log('inside submit', this.selDate);
  }

  validateFields() {
    // console.log(this.selDate, ' ', this.selFName, ' ', this.selLName, ' ', this.selMail, ' ', this.selPhNo, this.selTime)
    if (
      this.selDate != null ||
      this.selTime != null ||
      this.selFName !== null ||
      this.selLName !== null ||
      this.selMail !== null ||
      this.selPhNo !== null
    ) {
      return false;
    } else return true;
  }
}

<section class="hero-wrap"
  style="margin-top: 4rem; background-image:url('https://preview.colorlib.com/theme/convention/images/xbg_2.jpg.pagespeed.ic.WMrE4bft8B.webp')">
  <div class="overlay"></div>
  <div class="container-fluid">
    <div class="row no-gutters slider-text align-items-center justify-content-center" data-scrollax-parent="true">
      <div class="col-md-12 ftco-animate text-center pt-5 fadeInUp ftco-animated">
        <span class="subheading">Explore Great Business Opportunity</span>
        <h1 class="small-header mb-4" style="margin-bottom: 3rem !important;">Insurance <span>Agency </span>
          <span>Open</span> House</h1>
        <p class="loc-date">
          <span><i class="bi bi-geo-alt"></i> Troy Hills, New Jersey, USA</span>&nbsp;&nbsp;&nbsp;
          <span><i class="bi bi-calendar2-check"></i> Sunday, 29 August 2021</span>
        </p>

        
        <!-- <form action="forms/contact.php" method="post" role="form" class="php-email-form"> -->
          <div class="text-center"><button type="submit" class="button btn btn-sm btn-primary float-right reg-button" data-bs-toggle="modal"
            data-bs-target="#exampleModal">Registration</button></div>
        <!-- </form> -->

        <!-- <div id="timer" >
                    <div class="time" id="days">-124<span>Days</span></div>
                    <div class="time" id="hours">18<span>Hours</span></div>
                    <div class="time" id="minutes">27<span>Minutes</span></div>
                    <div class="time" id="seconds">31<span>Seconds</span></div>
                </div> -->
        <!-- <div id="timer" class="d-flex mb-3">
                    <div class="time" id="days">-124<span>Days</span></div>
                    <div class="time" id="hours">18<span>Hours</span></div>
                    <div class="time" id="minutes">27<span>Minutes</span></div>
                    <div class="time" id="seconds">31<span>Seconds</span></div>
                </div> -->
      </div>
    </div>
  </div>
</section>

<section id="contact" class="contact" style="padding-top: 3rem; padding-bottom: 5rem;">
  <div class="container">
    <!-- <div class="section-title">
        <h2>Contact Us</h2>
      </div> -->
    <!-- <div>
          <iframe style="border:0; width: 100%; height: 270px;" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" allowfullscreen></iframe>
        </div> -->
    <div>
      <div class="row ">
        <div class="col-lg-6">
          <div class="info">
            <div class="address">
              <i class="bi bi-geo-alt"></i>
              <h4>Embassy Suites by Hilton</h4>
              <p>909 Parsippany Blvd,
                Parsippany-Troy Hills, NJ 07054</p>
            </div>

            <div class="email">
              <i class="bi bi-calendar2-check"></i>
              <h4>Sunday, 29 August 2021</h4>
              <p>2:00 PM to 5:00 PM</p>
            </div>

            <div class="phone">
              <i class="bi bi-phone"></i>
              <h4>Call:</h4>
              <p>+1 888-770-5332</p>
            </div>

          </div>

        </div>

        <div class="col-lg-6 mt-5 mt-lg-0">
          
          <!-- <form action="forms/contact.php" method="post" role="form" class="php-email-form">
            <div class="text-center"><button type="submit" class="btn btn-sm btn-primary float-right reg-button" data-bs-toggle="modal"
              data-bs-target="#exampleModal">Registration</button></div>
          </form> -->
          <div class="">
            <span style="font-size: 20px; font-weight: 600;">Key topics</span>
            <ul>
              <li class="mt-3">How to Get State License & Get Started</li>
              <li class="mt-3">How to Work Part-time without any commitment</li>
              <li class="mt-3">How to Access Big Financial Company Products</li>
              <li class="mt-3">How to Build a 6-Figure Income with NO Investment</li>
              <!-- <li class="mt-3">How to Get State License & Get Started</li> -->
            </ul>
          </div>

          <!-- Modal -->
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header" style="text-align: center;">
                  <h5 class="modal-title" style="color: #AFD136;" id="exampleModalLabel">Event Registration
                  </h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <form style="margin: 20px;">

                    <!-- <div class="input-group input-group-lg mb-3">
                  <span class="input-group-text">
                    <i class="bi bi-clock"></i>
                  </span>
                  <select id="selTime" name="selTime" [(ngModel)]="selTime" style="font-size: 1rem;" class="form-control"
                    placeholder="Select date" class="form-control">
                    <option selected>Eastern Times (US and Canada)</option>
                  </select>
                </div> -->
                    <div class="input-group input-group-lg mb-3">
                      <span class="input-group-text"><i class="bi bi-person"></i></span>
                      <input type="text" style="font-size: 1rem;" id="selFName" name="selFName" [(ngModel)]="selFName"
                        class="form-control" placeholder="Enter first name...">
                    </div>
                    <div class="input-group input-group-lg mb-3">
                      <span class="input-group-text"><i class="bi bi-person"></i></span>
                      <input type="text" style="font-size: 1rem;" id="selLName" name="selLName" [(ngModel)]="selLName"
                        class="form-control" placeholder="Enter last name...">
                    </div>
                    <div class="input-group input-group-lg mb-3">
                      <span class="input-group-text"><i class="bi bi-envelope"></i></span>
                      <input type="text" style="font-size: 1rem;" id="selMail" name="selMail" [(ngModel)]="selMail"
                        class="form-control" placeholder="Enter email addres...">
                    </div>
                    <div class="input-group input-group-lg mb-3">
                      <span class="input-group-text"><i class="bi bi-telephone"></i>
                      </span>
                      <input type="text" style="font-size: 1rem;" id="selPhNo" name="selPhNo" [(ngModel)]="selPhNo"
                        class="form-control" placeholder="Enter phone number...">
                    </div>
                    <div class="d-grid mx-auto">
                      <button class="btn btn-primary" type="button" (click)="submitingData()"
                        style="background-color: #AFD136; border-color: #AFD136;" [disabled]="!validateFields()"
                        data-bs-dismiss="modal">Submit form</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

<!-- <section class="ftco-section ftco-counter img" id="section-counter" style="padding: 7rem;">
    <div class="overlay"></div>
    <div class="container">
        <div class="row">
            <div class="col-md-3 d-flex justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
                <div class="block-18 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <i class="bi bi-camera" style="font-size: 2em;"></i>
                    </div>
                    <div class="text pl-3" style="padding-left: 10px;">
                        <strong class="number" data-number="301">Embassy Suites by Hilton
                            909 Parsippany Blvd,
                            Parsippany</strong>
                        <span class="ml-3">Troy Hills, NJ 07054</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 d-flex justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
                <div class="block-18 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <i class="bi bi-calendar2-check" style="font-size: 2em;"></i>
                    </div>
                    <div class="text pl-3" style="padding-left: 10px;">
                        <strong class="number" data-number="102">102</strong>
                        <span class="ml-3">Events</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 d-flex justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
                <div class="block-18 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <i class="bi bi-award" style="font-size: 2em;"></i>
                    </div>
                    <div class="text pl-3" style="padding-left: 10px;">
                        <strong class="number" data-number="1050">850</strong>
                        <span class="ml-3">Photos</span>
                    </div>
                </div>
            </div>
            <div class="col-md-3 d-flex justify-content-center counter-wrap ftco-animate fadeInUp ftco-animated">
                <div class="block-18 d-flex align-items-center">
                    <div class="icon d-flex align-items-center justify-content-center">
                        <i class="bi bi-award" style="font-size: 2em;"></i>
                    </div>
                    <div class="text pl-3" style="padding-left: 10px;">
                        <strong class="number" data-number="1050">850</strong>
                        <span class="ml-3">Photos</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
 <header id="header" class="fixed-top d-flex align-items-center"> 
    <div class="container d-flex align-items-center">

      <h1 class="logo me-auto"><a href="#toppage"><img src="../../../assets/img/ibmwheaderlogo.png"></a></h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

      <nav id="navbar" class="navbar" [ngClass]="{'navbar-mobile': navIcon}">
        <ul>
          <!-- <li><a href="#hero" class="active">Home</a></li> -->
          <li><a href="#about">About Us</a></li>
          <!-- <li><a href="#clients">Clients</a></li> -->
          <li><a href="#services">FAQs</a></li>
          <li><a href="#contact">Contact Us</a></li>
          <li><a href="/events">Events</a></li>
          <!-- <li><a href="#contact">Blogs</a></li> -->
          <li><a class="getstarted" href="/video">Get Started</a></li>
          <!-- <li><a href="https://wa.me/918978853465" target="_blank" data-bs-toggle="tooltip" data-bs-placement="right" title="Chat for more details" >
            <img src="../../../assets/img/whatsapp.png" style="max-height: 34px;"/>
          </a></li> -->
          
        </ul>
        <i class="bi bi-list mobile-nav-toggle " (click)="callNavlist($event)"></i>
      </nav><!-- .navbar -->

    </div>
  </header><!-- End Header -->
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-mainpage',
  templateUrl: './mainpage.component.html',
  styleUrls: ['./mainpage.component.css']
})
export class MainpageComponent implements OnInit {
  navIcon = false;
  loader = false;
  fulltName: any;
  email: any;
  subject: any;
  message: any;

  constructor(private router: Router, public http: HttpClient) { }

  ngOnInit(): void {
    this.navIcon = false;
    console.log(this.navIcon)
  }

  secondPage() {
    this.router.navigate(['video']);
    console.log("Inside 2nd page...")
  }

  slides = ["aig.png", "sf.png", "allianz.png", "fg.png", "na.png", "nw.png", "prudential.jpg"];

  slideConfig = {
    "slidesToShow": 4,
    "slidesToScroll": 1,
    "nextArrow": "<div class='nav-btn next-slide'></div>",
    "prevArrow": "<div class='nav-btn prev-slide'></div>",
    "dots": true,
    "autoplay": true,
    "autoplaySpeed": 1000,
    "speed": 1000,
    "infinite": true
  };


  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  validate() {
    if(this.fulltName && this.email && this.subject && this.message) return true;
    else return false;
  }


  contactus() {
    var body = {
      firstName: this.fulltName,
      email: "raj@ibuildmywealth.com",
      userEmail: this.email,
      subject: "I Build My Wealth Contact Us",
      userSubject: this.subject,
      message: this.message,
      registerType: "contact"
    }
    console.log(body);
    this.http.post('https://ibuildmywealth.herokuapp.com/mail', body, {headers: {'Content-Type': 'application/json'}}).subscribe(result => {
      console.log(result);
      if(result) {
        this.clear();
      }
    }, err => {
      console.log(err);
    })
    // console.log('inside submit', this.selDate);
  }

  clear() {
    this.fulltName = null;
    this.email = null;
    this.subject = null;
    this.message = null;
  }

}


  
  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex justify-content-center align-items-center">
    <!-- <div data-bs-interval="5000" class="container" data-bs-ride="carousel"> -->
        <video controls id="background-video" autoplay loop muted poster="https://assets.codepen.io/6093409/river.jpg">
            <source src="https://assets.codepen.io/6093409/river.mp4" type="video/mp4">
          </video>
    <!-- </div> -->
  </section><!-- End Hero -->

  <main id="main">

          
<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header" style="background: rgb(33, 182, 215); text-align: center;">
        <h5 class="modal-title" id="exampleModalLabel">The Best Professional Business Opportunity in the World</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form style="margin: 20px;">
          <div class="input-group input-group-lg mb-3">
            <span class="input-group-text">
              <i class="bi bi-calendar-event"></i>
            </span>
            <!-- <span> -->
            <select id="selTime" name="selTime" [(ngModel)]="selDate" class="custom-select my-1 mr-sm-2"
              style="font-size: 1rem;" placeholder="Select date" class="form-control">
              <option>Choose...</option>
              <option *ngFor="let date of datesArray" value={{date.date}}>{{date.date | date: 'medium'}}</option>
              <!-- <option>Wednesday, 14 July 2021, 6:00 PM</option>
              <option>Wednesday, 14 July 2021, 6:00 PM</option> -->
            </select>
          <!-- </span> -->
          </div>
          <div class="input-group input-group-lg mb-3">
            <!-- <label for="inputState">State</label> -->
            <span class="input-group-text">
              <i class="bi bi-clock"></i>
            </span>
            <select id="selTime" name="selTime" [(ngModel)]="selTime" style="font-size: 1rem;" placeholder="Select date"
              class="form-control">
              <option>Eastern Times (US and Canada)</option>
              <!-- <option>...</option> -->
            </select>
          </div>
          <div class="input-group input-group-lg mb-3">
            <span class="input-group-text"><i class="bi bi-person"></i></span>
            <input type="text" style="font-size: 1rem;" id="selFName" name="selFName" [(ngModel)]="selFName"
              class="form-control" placeholder="Enter first name...">
          </div>
          <div class="input-group input-group-lg mb-3">
            <span class="input-group-text"><i class="bi bi-person"></i></span>
            <input type="text" style="font-size: 1rem;" id="selLName" name="selLName" [(ngModel)]="selLName"
              class="form-control" placeholder="Enter last name...">
          </div>
          <div class="input-group input-group-lg mb-3">
            <span class="input-group-text"><i class="bi bi-envelope"></i></span>
            <input type="text" style="font-size: 1rem;" id="selMail" name="selMail" [(ngModel)]="selMail"
              class="form-control" placeholder="Enter email addres...">
          </div>
          <div class="input-group input-group-lg mb-3">
            <span class="input-group-text"><i class="bi bi-telephone"></i>
            </span>
            <input type="text" style="font-size: 1rem;" id="selPhNo" name="selPhNo" [(ngModel)]="selPhNo"
              class="form-control" placeholder="Enter phone number...">
          </div>
          <div class="d-grid mx-auto">
            <button class="btn btn-primary" type="button" (click)="submitingData()"
              [disabled]="validateFields()">Submit form</button>
          </div>
        </form>
      </div>
      <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
    </div>
  </div>
</div>

    <!-- ======= Icon Boxes Section ======= -->
    <section id="icon-boxes" class="icon-boxes">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-lg-4 align-items-stretch mb-2 mb-lg-0" data-aos="fade-up" data-aos-delay="100">
            <div class="card icon-box">
              <div class="card-body">
                <h5 class="card-title title">
                  <div class="icon"><i class="bi bi-file-text"></i></div>
                  Register</h5>
                <p >With supporting text below as a natural lead-in to additional content.</p>
                <button type="button" class="btn btn-primary float-right" data-bs-toggle="modal" data-bs-target="#exampleModal">
                  <i class="bi bi-arrow-right-short"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 align-items-stretch mb-2 mb-lg-0" data-aos="fade-up" data-aos-delay="200">
            <div class="card icon-box">
              
              <div class="card-body">
                <h5 class="card-title title">
                  <div class="icon"><i class="bi bi-calendar-event"></i></div>
                  
              Demo hours</h5>
              <div class="" *ngFor="let dateNow of datesArray">
                <!-- <div *ngFor="let time for times; let i = index"> -->
                 <span>{{dateNow.date | date: 'medium'}}</span>
                <!-- </div> -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 align-items-stretch mb-2 mb-lg-0" data-aos="fade-up" data-aos-delay="300">          
             <div class="card icon-box">
              <div class="card-body">
                <h5 class="card-title title">
                  <div class="icon"><i class="bi bi-stopwatch"></i></div>
              Timer</h5>
              <div class="d-flex">
                <div class="timer d-flex">
                  <figure>
                    <div><span *ngIf="timeDifference && timeDifference.day<=9">0</span>{{timeDifference.day}}</div>
                    <figcaption>DAYS</figcaption>
                  </figure>
                  <figure>
                    <div><span *ngIf="timeDifference && timeDifference.hour<=9">0</span>{{timeDifference.hour}}</div>
                    <figcaption>HOURS</figcaption>
                  </figure>
                  <span>:</span>
                  <figure>
                    <div><span *ngIf="timeDifference && timeDifference.min<=9">0</span>{{timeDifference.min}}</div>
                    <figcaption>MINUTES</figcaption>
                  </figure>
                  <span>:</span>
                  <figure>
                    <div><span *ngIf="timeDifference && timeDifference.sec<=9">0</span>{{timeDifference.sec}}</div>
                    <figcaption>SECONDS</figcaption>
                  </figure>
                </div>
              </div>
              </div>
            </div>
          
          </div>

        </div>

      </div>
    </section><!-- End Icon Boxes Section -->

  </main><!-- End #main -->


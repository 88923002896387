import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  navIcon = false;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.navIcon = false;
    console.log(this.navIcon)
  }

  callNavlist(e) {
    console.log(e)
    this.navIcon = !this.navIcon;
    e.target.classList.toggle('bi-list')
    e.target.classList.toggle('bi-x')
    console.log("calling navbar ", this.navIcon)
  }

  secondPage() {
    this.router.navigate(['video']);
    console.log("Inside 2nd page...")
  }

}

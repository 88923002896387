import { Component, OnInit } from '@angular/core';
import { HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-videopage',
  templateUrl: './videopage.component.html',
  styleUrls: ['./videopage.component.css']
})
export class VideopageComponent implements OnInit {
  selDate: any = new Date();
  selTime: any = "some time";
  selFName: any;
  selLName: any;
  selMail: any;
  selPhNo: any;
  datesArray = [];

  constructor(public http: HttpClient) { }

  ngOnInit(): void {
    var curData = new Date();
    for (let i = 0; i < 3; i++) {
      this.datesArray.push({ date: curData.setHours(curData.getHours() + 2) });
    }
  }

  timeDifference = { day: 0, hour: 0, min: 0, sec: 0 };
  submitingData() {
    // var crrrDate = this.selDate - new Date()
    setInterval(() => {
      var currDate = Date.now();
      new Date(parseInt(this.selDate)).getSeconds() - new Date().getSeconds();
      var difference = this.selDate - Date.now();

      var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
      difference -= daysDifference * 1000 * 60 * 60 * 24;

      var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
      difference -= hoursDifference * 1000 * 60 * 60;

      var minutesDifference = Math.floor(difference / 1000 / 60);
      difference -= minutesDifference * 1000 * 60;

      var secondsDifference = Math.floor(difference / 1000);
      this.timeDifference = {
        day: daysDifference,
        hour: hoursDifference,
        min: minutesDifference,
        sec: secondsDifference,
      };
      // console.log(
      //   'difference = ' +
      //     daysDifference +
      //     ' day/s ' +
      //     hoursDifference +
      //     ' hour/s ' +
      //     minutesDifference +
      //     ' minute/s ' +
      //     secondsDifference +
      //     ' second/s '
      // );
      // console.log("currDate ", new Date(parseInt(this.selDate)).getSeconds()-new Date().getSeconds() );
    }, 1000);
    var body = {
      firstName: this.selFName,
      lastName: this.selLName,
      email: this.selMail,
      phone: this.selPhNo,
      subject: "I Build My Wealth video link",
      selectedDate: new Date(parseInt(this.selDate)),
      timezone: this.selTime,
      registerType: "register"
    }
    console.log(body);
    this.http.post('https://ibuildmywealth.herokuapp.com/mail', body, {headers: {'Content-Type': 'application/json'}}).subscribe(result => {
      console.log(result);
    }, err => {
      console.log(err);
    })
    // console.log('inside submit', this.selDate);
  }

  validateFields() {
    // console.log(this.selDate, ' ', this.selFName, ' ', this.selLName, ' ', this.selMail, ' ', this.selPhNo, ' ', this.selTime)
    if(this.selDate && this.selTime && this.selFName
       && this.selLName && this.selMail && this.selPhNo
       && this.selFName && this.selFName) {
      return true;
    } else return false;
  }

}
